import React, {useEffect, useState} from "react"
import {graphql} from "gatsby"
import ArticuloPage from "../../../components/glosario-financiero/articulo";
import ArticleDetail from "../../../components/glosario-financiero/GlosarioDetail";

const UniversityDynamicRoute = ({data}) => {
    const art = data.glossaries;
    const artSlug = art.slug;
    const artTitle = art.title;
    const artMetaTitle = art.metatitle;
    const artDesc = art.description;
    const htmlContent = art.htmlcontent;
    const artFaqGlosario = art.fat_glosario;


  const [showDynamicContent, setShowDynamicContent] = useState(false);

  useEffect(() => {
    setShowDynamicContent(true);
  }, []);

    return (
        showDynamicContent
            ? <ArticleDetail slug={artSlug}/>
            : <ArticuloPage
            slug={artSlug}
            title={artTitle}
            metatitle={artMetaTitle}
            description={artDesc}
            htmlcontent={htmlContent}
            faq_glosario={artFaqGlosario}
        />
      )
}

export const query = graphql`
query ($id: String) {
  glossaries(id: { eq: $id}) {
    slug
    title
    metatitle
    description
    htmlcontent
    faq_glosario
  }
}
`

export default UniversityDynamicRoute
